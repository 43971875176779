import { Col, Input, Row, Form } from 'antd';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import {
  getFunnelIdFromToken,
  getMixPanelEventExistingTrackingData,
  mixpanelEvents,
  saveMixpanelEventOccurrence
} from '../../../UI/utils/mixpanel';
import BuilderSettingsTitle from '../../components/sharedUI/BuilderSettingsTitle';
import {
  removeBuilderValidationError,
  updateChannelGeneralSettings,
  useChannelTemplatesData
} from '../../redux/builderSlice';
import { useDispatch } from 'react-redux';
import { CHANNEL_DATA_INPUTS } from '../../interfaces/builderSliceTypes';
import PhoneInput from '../../components/elements/ContactForm/components/PhoneInput';
import BuilderValidator, { BUILDER_VALIDATION_ERRORS } from '../BuilderValidator';
import {
  customStringFieldValidation,
  defaultPhoneNumberValidation
} from '../../../UI/utils/formValidation';

const MessagingSendingInputs = () => {
  const { phoneNumber, senderName, messageNumber } = useChannelTemplatesData();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleChange = (value: any, type: CHANNEL_DATA_INPUTS, event: mixpanelEvents) => {
    dispatch(
      updateChannelGeneralSettings({
        type,
        value
      })
    );
    if (value && !getMixPanelEventExistingTrackingData(event)) {
      mixpanel.track(event, {
        funnelId: getFunnelIdFromToken()
      });
      saveMixpanelEventOccurrence(event);
    }
  };

  useEffect(() => {
    form.submit();
  }, []);

  return (
    <>
      <BuilderValidator form={form} formType={BUILDER_VALIDATION_ERRORS.SMS_FORM}>
        <Form
          form={form}
          initialValues={{
            phoneNumber: phoneNumber,
            firma: senderName,
            messageNumber: messageNumber
          }}
        >
          <Row className="builder__settings-sidebar__row px-4">
            <Col span={24}>
              <BuilderSettingsTitle title="Firmenname" />
              <Form.Item
                className="mb-1"
                extra="Name der Firma bei Antworten."
                name="firma"
                rules={customStringFieldValidation(3, 15)}
              >
                <Input
                  placeholder="z.B. Musterfirma"
                  defaultValue={senderName}
                  data-testId="sender-name"
                  onChange={e => {
                    handleChange(
                      e.target.value,
                      CHANNEL_DATA_INPUTS.SENDER_NAME,
                      mixpanelEvents.ADDED_SENDER_INSIDE_MESSAGING_SETTINGS
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="builder__settings-sidebar__row px-4">
            <Col span={24}>
              <BuilderSettingsTitle title="Anrufnummer" />
              <Form.Item
                name="phoneNumber"
                className="mb-0 message-contact-number"
                extra="Nummer mit der der Bewerber angerufen wird."
                rules={defaultPhoneNumberValidation}
              >
                <PhoneInput
                  wrapperClassName="mb-0"
                  value={phoneNumber}
                  onChange={(value: any) => {
                    handleChange(
                      value,
                      CHANNEL_DATA_INPUTS.PHONE_NUMBER,
                      mixpanelEvents.ADDED_PHONE_NUMBER_INSIDE_MESSAGING_SETTINGS
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="builder__settings-sidebar__row px-4">
            <Col span={24}>
              <BuilderSettingsTitle title="Messaging-Nummer" />
              <Form.Item
                name="messageNumber"
                className="message-contact-number"
                extra="Nummer für Antworten via SMS oder Anruf."
                rules={defaultPhoneNumberValidation}
              >
                <PhoneInput
                  wrapperClassName="mb-0"
                  value={messageNumber}
                  onChange={(value: any) => {
                    handleChange(
                      value,
                      CHANNEL_DATA_INPUTS.Message_NUMBER,
                      mixpanelEvents.ADDED_PHONE_NUMBER_INSIDE_MESSAGING_SETTINGS
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </BuilderValidator>
    </>
  );
};

export default MessagingSendingInputs;
